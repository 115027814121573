import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import PaginationComponent from './PaginationComponent';
import './_table.scss';

interface T {
  limit: number;
  offset: number;
}

interface Props {
  columns: Array<object>;
  dataSource: Array<object>;
  currentPageHandler?: (data: T) => void;
  getCheckList?: any;
  count?: number;
  loading?: boolean;
  urlOffset?: number;
  showPagination?: boolean;
  urlLimit?: number;
  locale?: any;
  setRowKey?: string;
  expandable?: any;
  type?: 'default' | 'custom';
  expandableKey?: any;
  className?: string;
  rowClassName?: any;
}

const TableComponent: React.FC<Props> = ({
  columns,
  dataSource,
  currentPageHandler,
  getCheckList,
  count,
  loading,
  urlOffset,
  showPagination,
  urlLimit,
  locale,
  setRowKey,
  expandableKey,
  type,
  expandable,
  className,
  rowClassName
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]),
    rowKey = (record: any) => {
      switch (setRowKey) {
        case 'id':
          return record.id;

        case 'index':
          return dataSource.indexOf(record);

        default:
          return dataSource.indexOf(record);
      }
    },
    rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys: any) => {
        setSelectedRowKeys(selectedRowKeys);
      },
      onSelectNone: () => {
        setSelectedRowKeys([]);
      },
    };

  useEffect(() => {
    rowSelection.onSelectNone();
  }, [dataSource]);

  useEffect(() => {
    rowSelection.onSelectNone();
  }, [dataSource]);

  useEffect(() => {
    if (getCheckList) {
      getCheckList(selectedRowKeys);
    }
  }, [selectedRowKeys]);

  return (
    <div className="table-component">
      <Table
        className={`table ${className}`}
        columns={columns}
        dataSource={dataSource}
        {...(rowKey && { rowKey: (record) => rowKey(record) })}
        {...(getCheckList && { rowSelection: { ...rowSelection } })}
        {...(type !== 'default' && { pagination: false })}
        {...(loading && { loading: loading })}
        {...(locale && { locale: locale })}
        {...(rowClassName && {rowClassName: rowClassName})}
        {...(expandable && { expandable: {
          expandedRowRender: record => expandable(record),
          rowExpandable: record => record?.[expandableKey]?.length,
          expandIcon: ({expanded, onExpand, record}) => {
            if (record?.[expandableKey]?.length) {
              if (expanded) {
                return <div className="cta" onClick={(e)=> onExpand(record, e)}>Hide</div>
              } else {
                return <div className="cta" onClick={(e)=> onExpand(record, e)}>View</div>
              }
            }
          }
        }})}
      />
      {(!type || type !== 'default') && showPagination && (
        <PaginationComponent
          total={count}
          onPageChange={currentPageHandler}
          urlOffset={urlOffset}
          urlLimit={urlLimit}
        />
      )}
    </div>
  );
};

export default TableComponent;
