import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store/store';

const rootElement = document.getElementById('root');
// if (window.location.host.startsWith("www")
// ) {
//   window.location.href = window.location.protocol + "//" +
//     window.location.host.replace('www.', '') + window.location.pathname + window.location.search;
// }
if (!window.location.host.startsWith("www") && !window.location.host?.includes('localhost') && !window.location.host?.includes('127')) {
  window.location.href = window.location.protocol + "//www." +
    window.location.host + window.location.pathname + window.location.search;
}
render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,

  rootElement
);

// useEffect(() => {
// navigator.serviceWorker.getRegistrations().then(function (registrations) {
//   for (let registration of registrations) {
//     registration.unregister();
//   }
// });
// }, []);

// serviceWorkerRegistration.register();

// reportWebVitals();
