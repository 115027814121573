import { Alert, Checkbox, Col, Collapse, DatePicker, Drawer, Form, Radio, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import api from '../../../api/api';
import { toTitleCase } from '../../../utils/helper';
import Loader from '../../Loader/Loader';
import PrimaryButton, { SecondaryButton } from '../Button/Button';
import FilterTags from '../FilterTags/FilterTags';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  onClose: () => void;
  getParams: any;
  params: any;
  resetParams: any;
  filters: any[];
  products?: any[];
  tpas?: any[];
  policy_managers?: any[];
  claim_managers?: any[];
  providers?: any[];
  btnLoading: boolean;
  loading?: boolean;
  claimTypes?: any[];
  claimStatus?: any[];
  policies?: any[];
  memberFilters?: any[];
}

const FilterDrawer: React.FC<Props> = ({
  policy_managers,
  claim_managers,
  loading,
  visible,
  onClose,
  products,
  claimTypes,
  claimStatus,
  tpas,
  providers,
  params,
  resetParams,
  getParams,
  filters,
  btnLoading,
  policies,
  memberFilters,
}) => {
  const [form] = Form.useForm(),
    choices = useSelector((state: any) => state?.brokerconfig?.choices),
    [searchParams, setSearchParams] = useSearchParams(),
    [dateRange, setDateRange] = useState<any>([]),
    [activeFilters, setActiveFilters] = useState<number>(0),
    submitHandler = () => {
      form
        .validateFields()
        .then((values) => {
          let params = { ...values, offset: 0 };
          if (values.date_range) {
            delete params.date_range;
            params.start_date = moment(values.date_range[0]).format('YYYY-MM-DD');
            params.end_date = moment(values.date_range[1]).format('YYYY-MM-DD');
          }
          getParams(params);
        })
        .catch((info) => { });
    },
    removeFilter = (param: string, value: string | number) => {
      let newParams = { ...params };
      if (param === 'date_range') {
        newParams.start_date = undefined;
        newParams.end_date = undefined;
        form.setFieldsValue({ start_date: undefined, end_date: undefined });
      }
      if (param === 'insurer') {
        newParams.insurer = newParams.insurer.filter((item: any) => item !== value);
        form.setFieldsValue({ insurer: newParams.insurer });
      } else if (param === 'product') {
        newParams.product = newParams.product.filter((item: any) => item !== value);
        form.setFieldsValue({ product: newParams.product });
      } else if (param === 'tpa') {
        newParams.tpa = newParams.tpa.filter((item: any) => item !== value);
        form.setFieldsValue({ tpa: newParams.tpa });
      } else if (param === 'product_id') {
        newParams.product_id = newParams.product_id.filter((item: any) => item !== value);
        form.setFieldsValue({ product_id: newParams.product_id });
      } else if (param === 'support') {
        newParams.support = newParams.support.filter((item: any) => item !== value);
        form.setFieldsValue({ support: newParams.support });
      }
      // else if (filters.includes('status') && param === 'status') {
      //   newParams.status = newParams.status.filter((item: any) => item !== value);
      //   form.setFieldsValue({ status: newParams.status });
      // }
      else if (filters.includes('member_endorsement_status') && param === 'member_endorsement_status') {
        newParams.member_endorsement_status = newParams.member_endorsement_status.filter((item: any) => item !== value);
        form.setFieldsValue({ member_endorsement_status: newParams.member_endorsement_status });
      } else if (param === 'policy_id') {
        newParams.policy_id = newParams.policy_id.filter((item: any) => item !== value);
        form.setFieldsValue({ policy_id: newParams.policy_id });
      }
      else if (param === 'designation') {
        newParams.designation = newParams.designation.filter((item: any) => item !== value);
        form.setFieldsValue({ designation: newParams.designation });
      }

      else if (filters.includes('claim_status') && param === 'claim_status') {
        newParams.status = undefined;
        form.setFieldsValue({ status: newParams.status });
      }

      else {
        newParams[param] = undefined;
        form.setFieldsValue({ [param]: undefined });
      }
      getParams(newParams);
    },
    clearAll = () => {
      resetParams();
      form.setFieldsValue({
        is_demo: undefined,
        marked_important: undefined,
        provider_status: undefined,
        status: undefined,
        // status: filters.includes('status') ? [] : undefined,
        communication_status: undefined,
        start_date: undefined,
        end_date: undefined,
        member_endorsement_status: filters.includes('member_endorsement_status') ? [] : undefined,
        product: [],
        tpas: [],
        claimStatus: [],
        claimTypes: [],
        policy_manager_id: undefined,
        claims_manager_id: undefined,
        product_id: [],
        support: [],
        insurer: [],
        policy_id: [],
      });
    }, updateDateRange = (val: any) => {
      setDateRange(val);
      form.setFieldsValue({ start_date: val?.[0], end_date: val?.[1] });
    },
    organisation_id = useSelector((state: any) => state.auth?.organisation_id),
    [designations, setDesignations] = useState<any>(undefined);

  useEffect(() => {
    if (filters?.includes('designation') && organisation_id && designations === undefined) {
      setDesignations(false);
      api.hrms.hrmsFilterChoices(({ organisation_id: organisation_id }))
        .then((res) => { setDesignations(res?.data?.designation_filter); })
        .catch((err) => { console.log(err) })
    }
  }, [filters])

  useEffect(() => {
    setActiveFilters(
      ((filters?.includes('product') && params?.product?.length) || 0) +
      ((filters?.includes('tpa') && params?.tpa?.length) || 0) +
      ((filters?.includes('policy_id') && params?.policy_id?.length) || 0) +
      ((filters?.includes('designation') && params?.designation?.length) || 0) +
      ((filters?.includes('product_id') && params?.product_id?.length) || 0) +
      ((filters?.includes('support') && params?.support?.length) || 0) +
      ((filters?.includes('insurer') && params?.insurer?.length) || 0) +
      (filters?.includes('policy_managers') && params?.policy_manager_id ? 1 : 0) +
      (filters?.includes('claim_managers') && params?.claims_manager_id ? 1 : 0) +
      (filters?.includes('is_demo') && params?.is_demo ? 1 : 0) +
      (filters?.includes('marked_important') && params?.marked_important ? 1 : 0) +
      (filters?.includes('provider_status') && params?.provider_status ? 1 : 0) +
      ((filters?.includes('claim_status') && params?.status?.length) || 0) +
      (filters?.includes('claim_type') && params?.claim_type ? 1 : 0) +
      (filters?.includes('communication_status') && params?.communication_status ? 1 : 0) +
      (filters?.includes('date_range') && (params?.start_date || params?.end_date) ? 1 : 0) +
      (filters?.includes('status') && params?.status ? 1 : 0) +
      // ((filters?.includes('status') && params?.status?.length) || 0) +
      ((filters?.includes('member_endorsement_status') && params?.member_endorsement_status?.length) || 0)
    );
    form.setFieldsValue({
      date_range: params?.start_date || params?.end_date ? [moment(params?.start_date), moment(params?.end_date)] : undefined,
    })
  }, [
    params?.product,
    params?.product_id,
    params?.support,
    params?.insurer,
    params?.is_demo,
    params?.status,
    params?.communication_status,
    params?.start_date,
    params?.end_date,
    params?.policy_manager_id,
    params?.claims_manager_id,
    params?.provider_status,
    params?.claim_type,
    params?.tpa,
    params?.policy_id,
    params?.marked_important,
    params?.member_endorsement_status,
    params?.designation,
  ]);

  // useEffect(() => {
  //   console.log('params', params);
  //   Object?.keys(params)?.map((item: any) => {
  //     console.log(item, params[item]);
  //     searchParams.set(item, params[item]);
  //   });
  //   // searchParams.set('policy_manager_id', userData.memberId);
  //   setSearchParams(searchParams, { replace: true });
  //   // searchParams.forEach((value, key) => {
  //   //   p[key] = value;
  //   // });

  // }, [params]);

  // console.log(policy_managers);
  // console.log(params);


  return (
    <Drawer className="table-filter-drawer" visible={visible} closable={false} onClose={onClose} destroyOnClose>
      <Loader
        loading={loading}>
        <Row className="drawer-header" justify="space-between">
          <Col className="filter-title">Filters {activeFilters > 0 ? `(${activeFilters})` : null}</Col>
          <Col>
            <span className="cta" onClick={clearAll}>
              Clear All
            </span>
          </Col>
        </Row>
        {activeFilters > 0 && (
          <div className="active-filter-tags">
            <Row gutter={[12, 8]}>
              {filters?.includes('product') &&
                params?.product &&
                params?.product?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'product'}
                        name={products?.find((product: any) => product?.label === item)?.label}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('product_id') &&
                params?.product_id &&
                params?.product_id?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'product_id'}
                        name={products?.find((product: any) => product?.value === item)?.label}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('support') &&
                params?.support &&
                params?.support?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'support'}
                        name={choices?.support_type_choices?.find((obj: any) => obj?.id === item)?.name}
                        value={item?.id}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('insurer') &&
                params?.insurer &&
                params?.insurer?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'insurer'}
                        name={providers?.find((insurer: any) => insurer?.value === item)?.label}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('policy_id') &&
                params?.policy_id &&
                params?.policy_id?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'policy_id'}
                        name={policies?.find((policy: any) => policy?.id === item)?.policy_number}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('policy_managers') && params?.policy_manager_id && (
                <Col key={params?.policy_manager_id}>
                  <FilterTags
                    param={'policy_manager_id'}
                    name={`Policy manager: ${policy_managers?.find((policy_manager: any) => policy_manager?.id === params?.policy_manager_id)
                      ?.full_name
                      }`}
                    value={params?.policy_manager_id}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}

              {filters?.includes('designation') &&
                params?.designation &&
                params?.designation?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'designation'}
                        name={toTitleCase(item)}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}

              {filters?.includes('claim_managers') && params?.claims_manager_id && (
                <Col key={params?.claims_manager_id}>
                  <FilterTags
                    param={'claims_manager_id'}
                    name={`Claims manager: ${claim_managers?.find((claims_manager: any) => claims_manager?.id === params?.claims_manager_id)
                      ?.full_name
                      }`}
                    value={params?.claims_manager_id}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
              {filters?.includes('is_demo') && params?.is_demo && (
                <Col key={params.is_demo}>
                  <FilterTags
                    param={'is_demo'}
                    name={`Demo Status: ${toTitleCase(params.is_demo)}`}
                    value={params.is_demo}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
              {filters?.includes('marked_important') && params?.marked_important && (
                <Col key={params.marked_important}>
                  <FilterTags
                    param={'marked_important'}
                    name={`Marked Important: ${toTitleCase(params.marked_important)}`}
                    value={params.marked_important}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
              {filters?.includes('provider_status') && params?.provider_status && (
                <Col key={params.provider_status}>
                  <FilterTags
                    param={'provider_status'}
                    name={`Activation Status: ${toTitleCase(params.provider_status) === 'True' ? 'Activated' : 'Not Activated'
                      }`}
                    value={params.provider_status}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
              {filters?.includes('claim_status') &&
                params?.status &&
                params?.status?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'claim_status'}
                        name={toTitleCase(item)}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}
              {filters?.includes('claim_type') && params?.claim_type && (
                <Col key={params.claim_type}>
                  <FilterTags
                    param={'claim_type'}
                    name={`Claim Type: ${toTitleCase(params.claim_type)}`}
                    value={params.claim_type}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}

              {filters?.includes('status') && params?.status && (
                <Col key={params.status}>
                  <FilterTags
                    param={'status'}
                    name={`Member Status: ${toTitleCase(params.status)}`}
                    value={params.status}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}


              {/* {filters?.includes('status') &&
                params?.status &&
                params?.status?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'status'}
                        name={`Member Status: ${toTitleCase(item)}`}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })} */}

              {filters?.includes('member_endorsement_status') &&
                params?.member_endorsement_status &&
                params?.member_endorsement_status?.map((item: any) => {
                  return (
                    <Col key={item}>
                      <FilterTags
                        param={'member_endorsement_status'}
                        name={`Member Status: ${toTitleCase(item)}`}
                        value={item}
                        removeFilter={removeFilter}
                      />
                    </Col>
                  );
                })}


              {filters?.includes('communication_status') && params?.communication_status && (
                <Col key={params?.communication_status}>
                  <FilterTags
                    param={'communication_status'}
                    name={`Status: ${params.communication_status && params.communication_status === 'True' ? 'Sent' : 'Not Sent'
                      }`}
                    value={params.communication_status}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
              {filters?.includes('date_range') && (params?.start_date || params?.end_date) && (
                <Col key={params?.date_range}>
                  <FilterTags
                    param={'date_range'}
                    name={`Date Range : ${moment(params.start_date).format('DD MMM, YYYY')} - ${moment(params.end_date).format('DD MMM, YYYY')
                      }`}
                    value={params.date_range}
                    removeFilter={removeFilter}
                  />
                </Col>
              )}
            </Row>
          </div>
        )}

        <Form form={form} layout="vertical">
          {filters?.includes('is_demo') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Demo Status" key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="is_demo" initialValue={params?.is_demo}>
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      <Col span={24}>
                        <Radio value={'True'}>Yes</Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value={'False'}>No</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('marked_important') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Marked Important (2)" key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="marked_important" initialValue={params?.marked_important}>
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      <Col span={24}>
                        <Radio value={'True'}>Yes</Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value={'False'}>No</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('provider_status') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Product Activation Status" key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="provider_status" initialValue={params?.provider_status}>
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      <Col span={24}>
                        <Radio value={'true'}>Activated</Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value={'false'}>Not Activated</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('communication_status') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Communication Status" key="1">
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="communication_status"
                  initialValue={params?.communication_status}
                >
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      <Col span={24}>
                        <Radio value={'True'}>Sent</Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value={'False'}>Unsent</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('date_range') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Date Range" key="1">

                <Form.Item
                  label="Select a start date an end date"
                  wrapperCol={{ span: 24 }}
                  name={['date_range']}
                >
                  <RangePicker onChange={(val) => updateDateRange(val)}
                    disabledDate={(current) => current && current > moment().endOf('day')}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="start_date"
                  hidden
                >
                  <DatePicker
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="end_date"
                  hidden
                >
                  <DatePicker
                  />
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('claim_status') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Claim Status (${claimStatus?.length})`} key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="status" initialValue={params?.status}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {claimStatus?.map((status: any) => (
                        <Col span={24} key={status}>
                          <Checkbox value={status}>{toTitleCase(status)}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('claim_type') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Claim Type (${claimTypes?.length})`} key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="claim_type" initialValue={params?.claim_type}>
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {claimTypes?.map((type: any) => (
                        <Col span={24} key={type}>
                          <Radio value={type}>{toTitleCase(type)}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('status') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Status (${memberFilters?.length})`} key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="status" initialValue={params?.status}>
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {
                        memberFilters?.map((item: any, index: number) => {
                          return <Col span={24} key={index}>
                            <Radio value={item?.value}>{item?.title}</Radio>
                          </Col>
                        })
                      }
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('member_endorsement_status') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header="Status" key="1">
                <Form.Item wrapperCol={{ span: 24 }} name="member_endorsement_status" initialValue={params?.member_endorsement_status}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      <Col span={24}>
                        <Checkbox value="PENDING">Pending</Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox value="SENT_TO_INSURER">Sent to Insurer</Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox value="ACTIVE">Active</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}


          {filters?.includes('product_id') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Products (${products?.length})`} key="2">
                <Form.Item wrapperCol={{ span: 24 }} name="product_id" initialValue={params?.product_id}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {products?.map((product: any) => (
                        <Col span={24} key={product.value}>
                          <Checkbox value={product.value}>{product.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('support') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Support Type (${choices?.support_type_choices?.length})`} key="2">
                <Form.Item wrapperCol={{ span: 24 }} name="support" initialValue={params?.support}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {choices?.support_type_choices?.map((obj: any) => (
                        <Col span={24} key={obj?.id}>
                          <Checkbox value={obj?.id}>{obj.name}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('insurer') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Providers (${providers?.length})`} key="3">
                <Form.Item wrapperCol={{ span: 24 }} name="insurer" initialValue={params?.insurer}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {providers?.map((provider: any) => (
                        <Col span={24} key={provider.value}>
                          <Checkbox value={provider.value}>{provider.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('product') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Products (${products?.length})`} key="2">
                <Form.Item wrapperCol={{ span: 24 }} name="product" initialValue={params?.product}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {products?.map((product: any) => (
                        <Col span={24} key={product.value}>
                          <Checkbox value={product.label}>{product.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('tpa') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`TPA (${tpas?.length})`} key="2">
                <Form.Item wrapperCol={{ span: 24 }} name="tpa_id" initialValue={params?.tpa}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {tpas?.map((tpa: any) => (
                        <Col span={24} key={tpa.id}>
                          <Checkbox value={tpa.id}>{tpa.name}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('policy_id') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Policy (${policies?.length})`} key="3">
                <Form.Item wrapperCol={{ span: 24 }} name="policy_id" initialValue={params?.tpa}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {policies?.map((policy: any) => (
                        <Col span={24} key={policy.id}>
                          <Checkbox value={policy.id}>{policy.policy_number} ({policy?.product}, {policy?.expired ? 'Expired' : 'Active'})</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('policy_managers') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Policy Manager (${policy_managers?.length})`} key="3">
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="policy_manager_id"
                  initialValue={params?.policy_manager_id}
                >
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {policy_managers?.map((manager: any) => (
                        <Col span={24} key={manager.id}>
                          <Radio value={manager?.id}>{manager?.full_name}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}


          {filters?.includes('designation') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Designations (${designations ? designations?.length : 'NA'})`} key="designations">
                {!designations && <Alert style={{ padding: '8px' }} message="No designations to filter from" />}
                <Form.Item wrapperCol={{ span: 24 }} name="designation" initialValue={params?.designation}>
                  <Checkbox.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {designations && designations?.map((item: any) => (
                        <Col span={24} key={item}>
                          <Checkbox value={item}>{toTitleCase(item)}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          {filters?.includes('claim_managers') && (
            <Collapse bordered={false} expandIconPosition="right">
              <Panel header={`Claims Manager (${claim_managers?.length})`} key="3">
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="claims_manager_id"
                  initialValue={params?.claims_manager_id}
                >
                  <Radio.Group>
                    <Row gutter={[0, 16]} justify="space-around">
                      {claim_managers?.map((manager: any) => (
                        <Col span={24} key={manager.id}>
                          <Radio value={manager?.id}>{manager?.full_name}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Panel>
            </Collapse>
          )}

          <Row align="middle" className="drawer-footer" gutter={16}>
            <Col>
              <SecondaryButton onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton loading={btnLoading} onClick={submitHandler}>
                Apply
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </Loader>
    </Drawer>
  );
};

export default FilterDrawer;
