
import { CaretDownOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Dropdown, Image, Row, Space } from 'antd';
import { createBrowserHistory } from 'history';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../../store/auth';
import { toTitleCase } from '../../utils/helper';
import { CustomModal } from '../Modals/CustomModal';
import NavbarMobile from '../Navbar/NavbarMobile';
import EmployerEmployeeToggle from '../UI/Toggle/EmployerEmployeeToggle';
import './_header-strip.scss';

const mobileMenu = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M13.5 13.5H18V18H13.5V13.5ZM6.75 13.5H11.25V18H6.75V13.5ZM0 13.5H4.5V18H0V13.5ZM13.5 6.75H18V11.25H13.5V6.75ZM6.75 6.75H11.25V11.25H6.75V6.75ZM0 6.75H4.5V11.25H0V6.75ZM13.5 0H18V4.5H13.5V0ZM6.75 0H11.25V4.5H6.75V0ZM0 0H4.5V4.5H0V0Z" />
</svg>


const HeaderStrip: React.FC = () => {
  const dispatch = useDispatch(),
    logoutHandler = () => {
      dispatch(authActions.logout());
    },
    themeDetails = useSelector((state: any) => state.theme),
    navigate = useNavigate(),
    [modalVisible, setModalVisible] = useState(false),
    name = useSelector((state: any) => state.auth.name),
    role = useSelector((state: any) => state.auth.roles),
    [visible, setVisible] = useState(false);

  const menu = () => {
    return (
      <div className="header-menu">
        <div className="item" onClick={() => navigate('profile')}>
          Profile
        </div>
        <div className="item" onClick={() => setModalVisible(true)}>
          Logout
        </div>
      </div>
    );
  };

  const closeNavbar = () => {
    setVisible(false);
  };

  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(segment => segment);
  const title = (() => {
    if (pathSegments.length === 0) return 'Home';
    const lastSegment = pathSegments[pathSegments.length - 1] as any;
    if (!isNaN(lastSegment)) {
      // If the last segment is a number and there is an element before it
      if (pathSegments.length > 1) return pathSegments[pathSegments.length - 2];
      return 'Home'; // If there is only one element in the array and it's a number, default to 'Home'
    }
    return lastSegment;
  })(); const isHomeInURL = location.pathname.includes('home');
  const history = createBrowserHistory({}),
    brandName = useSelector((state: any) => state.theme.brandName);


  const goBack = () => {
    if (pathSegments.length > 1) {
      // Remove the last segment and join the segments to form the new URL
      pathSegments.pop();
      const newPath = '/' + pathSegments.join('/');
      if (newPath?.includes('how-to-claim')) {
        navigate('claims');
      }
      else {
        navigate(newPath);
      }
    }
  };


  return (<>
    {visible && <NavbarMobile close={closeNavbar} visible={visible} />}

    <CustomModal
      isModalVisible={modalVisible}
      confirmHandler={logoutHandler}
      cancelHandler={() => {
        setModalVisible(false);
      }}
      title={`Are you sure you want to logout?`}
      cancelButtonText="NO"
      confirmButtonText="YES"
    />
    <div className='header-strip-parent'>
      <Row justify="space-between" align="middle" className="header-strip header-web">
        <Col>
          <Image className="pointer header-logo" src={themeDetails?.logoUrl} preview={false} onClick={() => navigate('/')} />
        </Col>
        <Col className='header-web'>
          <Row align="middle" gutter={[24, 0]}>
            {brandName && <><Col>
              <div className='love-badge'>
                <Row align='middle' gutter={8}>
                  <Col className='we'>
                    We
                    <svg style={{ marginLeft: '8px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11615_80448)">
                        <rect width="14" height="14" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99731 2.99589C5.83102 1.6324 3.88615 1.26562 2.42487 2.51417C0.963594 3.76272 0.757867 5.85024 1.90542 7.3269C2.85953 8.55465 5.747 11.1441 6.69336 11.9822C6.79924 12.0759 6.85218 12.1228 6.91393 12.1412C6.96782 12.1573 7.02679 12.1573 7.08069 12.1412C7.14244 12.1228 7.19538 12.0759 7.30125 11.9822C8.24761 11.1441 11.1351 8.55465 12.0892 7.3269C13.2367 5.85024 13.0561 3.74959 11.5697 2.51417C10.0833 1.27876 8.1636 1.6324 6.99731 2.99589Z" fill="#E27575" stroke="#E27575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_11615_80448">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Col>
                </Row>
                <Row align='middle'>
                  <Col className='organisation'>
                    {brandName}
                  </Col>
                </Row>
              </div>
            </Col>
              <Col className="header-strip-text pipe">|</Col></>}
            <Col className="header-strip-text">
              <Dropdown trigger={["hover"]} className="pointer" overlay={menu} placement="bottomRight">
                <Space>
                  {name}&nbsp;
                  <CaretDownOutlined />
                </Space>
              </Dropdown>
            </Col>
            {role.includes('employer') && (
              <>
                <Col className="header-strip-text pipe">|</Col>
                <Col>
                  <EmployerEmployeeToggle />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      {isHomeInURL && <Row justify="center" align="middle" className={`header-strip show-on-mobile`}>
        <Col>
          <Image className="pointer header-logo" src={themeDetails?.logoUrl} preview={false} onClick={() => navigate('/')} />
        </Col>
      </Row>}

      {!isHomeInURL && <Row justify="start" align="middle" className={`header-strip show-on-mobile primary-bg`}>
        {pathSegments.length > 1 && <Col>
          <span className='back-button' onClick={goBack}>
            <LeftOutlined />
          </span>
        </Col>
        }
        <Col>
          <span className='title'>
            {toTitleCase(title?.replaceAll('-', ' '))}
          </span>
        </Col>
      </Row>}

      {brandName && <Row className='extend-love-badge show-on-mobile' justify='center'>
        <Col>
          <Row align='middle'>
            <Col className='we'>We</Col>
            <Col className='heart'>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_11605_77695)">
                  <rect width="14" height="14" fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9934 2.99589C5.82711 1.6324 3.88225 1.26562 2.42097 2.51417C0.959688 3.76272 0.75396 5.85024 1.90151 7.3269C2.85562 8.55465 5.7431 11.1441 6.68945 11.9822C6.79533 12.0759 6.84827 12.1228 6.91002 12.1412C6.96391 12.1573 7.02289 12.1573 7.07678 12.1412C7.13853 12.1228 7.19147 12.0759 7.29735 11.9822C8.24371 11.1441 11.1312 8.55465 12.0853 7.3269C13.2328 5.85024 13.0522 3.74959 11.5658 2.51417C10.0794 1.27876 8.15969 1.6324 6.9934 2.99589Z" fill="#E27575" stroke="#E27575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11605_77695">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Col>
            <Col className='organisation'>{brandName}</Col>
          </Row>
        </Col>
      </Row>}
    </div>
  </>
  );
};

export default HeaderStrip;
